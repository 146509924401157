<template>
    <div>
         <HeaderLayout 
            :signbook-merchant-logo="signbookMerchantLogo"
            :is-reminder-visible="true">
        </HeaderLayout>
        <v-container v-show="$vuetify.breakpoint.xs">
            <v-row justify="space-around">
                <div>
                    <span class="font-weight-medium title">Montant total dû :</span> <span class="title font-weight-bold light-blue--text accent-4">{{ demand.product.insuranceSubscribed ? demand.product.totalAmountDueWithInsurance : demand.product.totalAmountDue }}€</span>
                </div>
                <div style="display: flex; justify-content: center; align-content: center; flex-direction: column; cursor: pointer;" @click="showDetailsMobile = !showDetailsMobile">
                    <div v-bind:class="{'recap-details': true, 'font-weight-bold': true, 'recap-details-opened': showDetailsMobile, 'recap-details-closed': !showDetailsMobile}">Détails</div>
                </div>
            </v-row>
            <v-row v-show="showDetailsMobile">
                <v-container class="order-details">
                    <v-row justify="center">
                        <v-col>
                            <div style="color: #fff; font-size: 16px; text-decoration: underline; text-align: center">Votre proposition : </div>
                        </v-col>
                    </v-row>
                    <v-row justify="start" align="center" class="mt-0">
                        <v-col>
                            <div>Montant d'achat : <strong>{{ amountAsked }}€</strong></div>
                        </v-col>
                        <v-col>
                            <div>TAEG fixe : <strong>{{ taeg }}%</strong></div>
                        </v-col>
                    </v-row>
                    <v-row justify="start" align="center" class="mt-0">
                        <v-col>
                            <div>Nombre de mensualités : <strong>{{ duration }}</strong></div>
                        </v-col>
                        <v-col>
                            <div>Coût de l'assurance : <strong>{{ insuranceCost }}€</strong></div>
                        </v-col>
                    </v-row>
                    <v-row justify="start" align="center" class="mt-0">
                        <v-col>
                            <div>{{ duration }} mensualités de : <strong>{{ totalMonthly }}€</strong></div>
                        </v-col>
                        <v-col>
                            <div>Coût du crédit : <strong>{{ loanCost }}€</strong></div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-row>
        </v-container>

        <div v-if="signbookError == false">
            <v-container class="mt-5">
                <v-row class="mb-6">
                    <!-- Breadcrumb desktop -->
                    <v-col cols="12" sm="3" md="3" lg="3" class="pt-0 mb-5" v-show="!$vuetify.breakpoint.xs"></v-col>
                    <v-col cols="12" sm="7" md="7" lg="3" class="pt-0" order-lg="1" v-show="!$vuetify.breakpoint.xs">
                        <div id="summary-top" class="pt-2 pb-2">
                            Votre proposition de<br />
                            <strong>FINANCEMENT</strong><br />
                            <img width="100" :src="floaLogoRvb" style="display: inline-block; vertical-align: top;" />
                            <v-badge id="loan-duration-badge"
                                     inline
                                     color="white"
                                     :value="`${ duration }X`"
                                     :content="`${ duration }X`"></v-badge>
                        </div>
                        <div class="summary-middle pt-3 pb-3">
                            Montant d'achat : <strong class="display-right">{{ amountAsked }}€</strong>
                        </div>
                        <div class="summary-middle pt-3 pb-3">
                            Nombre de mensualités : <strong class="display-right">{{ duration }}</strong>
                        </div>
                        <div class="summary-middle pt-3 pb-3">
                            {{ duration }} mensualités de : <strong class="display-right">{{ totalMonthly }}€</strong>
                        </div>
                        <div class="summary-middle pt-3 pb-3">
                            TAEG fixe : <strong class="display-right">{{ taeg }}%</strong>
                        </div>
                        <div class="summary-middle pt-3 pb-3">
                            Coût de l'assurance : <strong class="display-right">{{ insuranceCost }}€</strong>
                        </div>
                        <div class="summary-middle pt-3 pb-3">
                            Coût du crédit : <strong class="display-right">{{ loanCost }}€</strong>
                        </div>
                        <div id="summary-bottom" class="pt-4 pb-4">
                            <span class="font-weight-medium title">Montant total dû :</span> <span class="title font-weight-bold display-right light-blue--text accent-4">{{ demand.product.insuranceSubscribed ? demand.product.totalAmountDueWithInsurance : demand.product.totalAmountDue }}€</span>
                        </div>
                    </v-col>
                    <v-col cols="12" md="12" class="d-lg-none" v-show="!$vuetify.breakpoint.xs">
                        <hr class="mt-3 mb-3" />
                    </v-col>
                    <Contract
                        :gender="demand.customers[0].gender"
                        :lastName="demand.customers[0].lastName"
                        :is-loading="isLoading">
                    </Contract>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="5" md="5" lg="3" class="pt-0 mb-5" v-show="!$vuetify.breakpoint.xs" />
                    <v-col cols="12" lg="6" class="pt-0 pl-0 pr-0">
                        <LegalNotice></LegalNotice>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div class="height-center" v-else>
            <error></error>
        </div>
    </div>
</template>

<script>
    import LegalNotice from '../shared/LegalNotice.vue';
    import HeaderLayout from '../shared/HeaderLayout.vue';
    import Contract from '../shared/Contract.vue';
        
    export default {
        name: 'SignCCL122436',
        components: {
            LegalNotice,
            HeaderLayout,
            Contract
        },
        props: {
            signbookError: Boolean,
            signbookUrl: String,
            signbookToken: String,
            signbookMerchantLogo: {
                type: String,
                default: ''
            },
            nthSignbook: Object,
            demand: Object,
            isLoading: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                floaLogo: require('../../../public/img/logo_floa.svg'),
                floaLogoRvb: require('../../../public/img/logo_floa_negative.svg'),
                showDetailsMobile: false,
            }
        },
    computed: {
        amountAsked: function ()
        {
            return this.demand.product.amountAsked;
        },
        taeg: function ()
        {
            return this.demand.product.taeg;
        },
        duration: function ()
        {
            return this.demand.product.duration;
        },
        insuranceCost: function ()
        {
            return Number.parseFloat( this.demand.product.insuranceSubscribed ? ( this.demand.product.insuranceMonthly * this.demand.product.duration ) : 0 ).toFixed( 2 );
        },
        totalMonthly: function ()
        {
            return this.demand.product.insuranceSubscribed ? this.demand.product.totalMonthlyWithInsurance : this.demand.product.totalMonthly;
        },
        loanCost: function ()
        {
            return Number.parseFloat( this.demand.product.totalAmountDue - this.demand.product.amountAsked ).toFixed( 2 );
        }
    },
}
</script>
<style>
    #app > div {
        background-color: #F3F3F3 !important;
    }

    #app {
        font-family: 'Poppins'
    }

    #loan-duration-badge > span > span {
        margin-top: 14px;
        background-color: #FFF !important;
        border-color: #FFF !important;
        color: #009FFF !important;
        font-weight: bold !important;
        font-size: 0.9em;
        width: 45px;
        height: 21px;
    }
</style>
<style scoped>
    #toolbar {
        text-align: center !important;
    }

    .toolbar-title {
        margin-left: 25px;
        font-size: 0.85em;
        color: #808080;
    }

    .v-sheet.v-card:not(.v-sheet--outlined) {
        box-shadow: none !important;
    }

    #summary-top {
        background-color: #009FFF;
        color: #FFF;
        text-align: center;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

    .summary-middle {
        padding-left: 15px;
        padding-right: 15px;
        background-color: #FFF;
        font-size: 0.9em;
        border-bottom: 1px solid #d3d3d3;
    }

    #summary-bottom {
        padding-left: 15px;
        padding-right: 15px;
        background-color: #FFF;
        font-size: 0.9em;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }

    .display-right {
        float: right;
    }

    .floa-color {
        color: #009FFF;
    }

    .floa-color-muted {
        color: #009FFF;
        opacity: 0.3;
    }

    .step-progress {
        position: relative;
        list-style: none;
    }

    .step-progress-item {
        position: relative;
        counter-increment: list;
        text-align: right;
        padding-right: 70px;
    }

        .step-progress-item:not(:last-child) {
            padding-bottom: 30px;
            color: #009FFF;
            opacity: 0.3;
        }

        .step-progress-item:last-child {
            color: #009FFF;
        }

        .step-progress-item::before {
            display: inline-block;
            content: '';
            position: absolute;
            right: 0;
            height: 100%;
            width: 19px;
        }

        .step-progress-item::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            right: 3px;
            width: 27px;
            height: 27px;
            border: 2px solid #009FFF;
            border-radius: 50%;
            background-color: #009FFF;
        }

        .step-progress-item.is-done::before {
            border-left: 5px solid #009FFF;
        }

        .step-progress-item.is-done::after {
            content: "✔";
            font-size: 14px;
            padding-top: 2px;
            color: #FFF;
            text-align: center;
            border: 2px solid #009FFF;
            background-color: #009FFF;
        }

        .step-progress-item.current::before {
            border-left: 2px solid #009FFF;
        }

        .step-progress-item.current::after {
            content: counter(list);
            padding-top: 1px;
            width: 19px;
            height: 18px;
            top: -4px;
            left: -40px;
            font-size: 14px;
            text-align: center;
            color: green;
            border: 2px solid #009FFF;
            background-color: white;
        }

    .step-progress strong {
        display: block;
    }

    .breadcrumb-item {
        border-radius: 50%;
        display: inline-block;
        height: 10px;
        width: 10px;
        background-color: #c9d4d8;
    }

    .active {
        background-color: #009fff;
    }

    .recap-details-closed::before {
        content: url("../../../public/img/recap_plus.svg");
    }

    .recap-details-opened::before {
        content: url("../../../public/img/recap_minus.svg");
    }

    .recap-details::before {
        position: relative;
        left: -2px;
    }

    .recap-details {
        font-size: 12px;
    }

    .order-details {
        color: #fff;
        background-color: #009fff;
        border-radius: 0 0 10px 10px;
        font-size: 16px;
    }
</style>

<style scoped>
    @import '../../assets/css/fonts.css';
</style>
